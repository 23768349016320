// import Dashboard from '@/views/SimEOC/dashboard/Dashboard.vue';
import UserProfile from '@/views/SimEOC/user/UserProfile.vue';
import ChangePassword from '@/views/SimEOC/user/ChangePassword.vue';
import EditUser from '@/views/SimEOC/user/admin/EditUser.vue';
import Users from '@/views/SimEOC/user/Users.vue';

import Customer from '@/views/SimEOC/customer/Customer.vue';

// import Map from '@/views/SimEOC/Map.vue'
// import Charts from '@/views/SimEOC/Charts.vue'

import MediaLibrary from '@/views/SimEOC/MediaLibrary.vue'
import MediaLibraryDetail from '@/views/SimEOC/MediaLibraryDetail.vue'
import MediaLibraryUpload from '@/views/SimEOC/MediaLibraryUpload.vue'


// import Endless from '@/views/SimEOC/endless/Endless.vue'
import EditorJS from '@/views/SimEOC/endless/EditorJS.vue'


import ExerciseTemplates from '@/views/SimEOC/exercise/ExerciseTemplates.vue';
import Exercise from '@/views/SimEOC/exercise/Exercise.vue';
// import ExerciseBuilder from '@/views/SimEOC/exercise/ExerciseBuilder.vue';
import ExerciseForm from '@/views/SimEOC/exercise/ExerciseForm.vue';
import Library from '@/views/SimEOC/library/Library.vue';


const routes = [
{
    path: 'user',
    name: 'user',
    component: UserProfile
},
{
    path: 'user/:id',
    name: 'user-details',
    component: UserProfile
},
{
    path: 'customer',
    name: 'customer-details',
    component: Customer
},
{
    path: 'customer/:id',
    name: 'customer-details-id',
    component: Customer
},
// {
//     path: 'endless',
//     name: 'endless',
//     component: Endless
// },
{
    path: 'editorjs',
    name: 'editorjs',
    component: EditorJS
},
// {
//     path: 'map',
//     name: 'map',
//     component: Map
// },
// {
//     path: 'charts',
//     name: 'charts',
//     component: Charts
// },
{
    path: 'edit-user/:userId',
    name: 'edit-user',
    component: EditUser
},
{
    path: 'users',
    name: 'users',
    component: Users
},
{
    path: 'change-password',
    name: 'change-password',
    component: ChangePassword
},
{
    path: 'exercise-templates',
    name: 'exercise-templates',
    component: ExerciseTemplates
},
{
    path: 'exercise-form',
    name: 'exercise-form',
    component: ExerciseForm,
    props: true
},
{
    path: 'exercise-form/:exerciseId',
    name: 'exercise-form-update',
    component: ExerciseForm,
    props: true
},
{
    path: 'exercise/:id',
    name: 'exercise',
    component: Exercise
},
// {
//     path: 'exercise-builder/:exercise_id',
//     name: 'exercise-builder',
//     component: ExerciseBuilder
// },
// {
//     path: 'exercise-builder/:exercise_id/:widget',
//     name: 'exercise-builder-widget',
//     component: ExerciseBuilder
// },
// {
//     path: 'exercise-builder/:exercise_id/:widget/:id',
//     name: 'exercise-builder-copy',
//     component: ExerciseBuilder
// },
// {
//     path: 'exercise-builder/:exercise_id/:widget/:id/edit',
//     name: 'exercise-builder-edit',
//     component: ExerciseBuilder
// },
{
    path: 'library/:widget',
    name: 'library',
    component: Library
},
{
    path: 'library/:widget/detail/:id',
    name: 'library-detail',
    component: Library
},
{
    path: 'library/:widget/form/:form/:id',
    name: 'library-form-edit',
    component: Library
},
{
    path: 'library/:widget/form/:form',
    name: 'library-form-create',
    component: Library
},
{
    path: 'library/:widget/form/:form/:id',
    name: 'library-form-copy',
    component: Library
},
{
    path: 'media-library',
    name: 'media-library',
    component: MediaLibrary
},
{
    path: 'media-library/:id',
    name: 'media-library-detail',
    component: MediaLibraryDetail
},
{
    path: 'media-library-upload',
    name: 'media-library-upload',
    component: MediaLibraryUpload
}
];


export default routes;
