<template>
    <div class="w-100 h-100">
      <div class="header pb-6 d-flex align-items-center profile-header">
        <!-- Mask -->
        <span class="mask bg-gradient-default opacity-8"></span>
        <!-- Header container -->
        <b-container fluid class="d-flex align-items-center">
          <b-row >
            <b-col lg="12" md="12">
              <h1 class="display-2 text-white">Hello, {{  myCustomer.name }}</h1>
            </b-col>
          </b-row>
        </b-container>
      </div>
  
      <b-container fluid class="mt--6">
        <b-row>
          <!-- <b-col xl="6">
            <b-card no-body class="card-profile" img-src="img/theme/img-1-1000x600.jpg" alt="Image placeholder" img-top>

              <b-card-body class="pt-7">
                <div class="text-center">
                  <h5 class="h3">
                    {{ myUser.name || myUser.email }}, <span class="font-weight-light" v-if="myUser._id == user._id">(me), {{ myCustomer.name }}</span>
                    <span class="font-weight-light" v-else>{{ myCustomer.name }}</span>
                  </h5>
                  <div class="h5 font-weight-300">
                    <i class="ni location_pin mr-2"></i> {{ myUser.email }}
                  </div>
                  <div class="h5 mt-4" v-if="myUser.systemRoles && myUser.systemRoles.length">
                    <i class="ni business_briefcase-24 mr-2"></i>System Roles
                  </div>
                  <div v-if="myUser.systemRoles && myUser.systemRoles.length">
                    <i class="ni education_hat mr-2"></i> {{ roles }}
                  </div>
                </div>
              </b-card-body>
            </b-card>
  
          </b-col> -->
          <b-col>
            <card>
  
                <h6 class="heading-small text-muted mb-4">Update Company Information</h6>
                <b-container fluid>
                  <b-row class="my-3">
                    <b-col>
                      <b-form-input
                        size="lg"
                        v-model="customerForm.name"
                        placeholder="Name"
                      ></b-form-input>
                    </b-col>
                  </b-row>

                  <b-row class="my-3">
                    <b-col>
                      <b-form-textarea
                        v-model="customerForm.profile"
                        placeholder="Enter Company Profile"
                        rows="6"
                    ></b-form-textarea>
                    </b-col>
                  </b-row>
  
                  <b-row class="my-3">
                    <b-col>
                      <b-form-input
                        size="lg"
                        v-model="customerForm.simcell"
                        placeholder="Enter Default SimCell Name"
                      ></b-form-input>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <h3>Select default location</h3>
                      <MapPreview
                          :item="customerForm"
                          :icon="selectedIcon"
                          :canEdit="true"
                          :exercise="{}"
                          v-on:update:LatLng="updateLatLng($event)"
                          v-on:update:Zoom="updateZoom($event)"
                      />
                    </b-col>
                  </b-row>
  
                  <b-row class="my-3">
                    <b-col>
                      <b-button
                        variant="primary"
                        block
                        size="lg"
                        @click="updateCustomer()"
                      >
                        Save
                      </b-button>
                    </b-col>
                  </b-row>
                </b-container>

            </card>
          </b-col>
        </b-row>
        
      </b-container>
    </div>
  </template>
  
  <script>
  
  import { HTTP } from "@/util/http-common.js";
  
  import { mapActions, mapState } from "vuex";
  
  import * as _ from "lodash";
  
  export default {
    // mixins: [BaseDialogMixin, BaseAlertMixin],
    data: function () {
      return {
        customerForm: {},
        roles: '',
        myUser: {},
        myCustomer: {},
        selectedIcon: "/simeoc/simeoc-map-icons/blue.png",
      };
    },
    computed: {
      ...mapState("user", ["user", "myGroups"]),
    },
    created: async function () {
      const that = this;
      let customerID = null;

      const response = await that.$http.get(`/user/${that.$store.state.user.user._id}`);
      that.myUser = response.data;
  
      const roles = [];
      _.each(that.myUser.systemRoles, (role) => {
        roles.push(role.split('-').join(' '))
      });
      that.roles = roles.join(', ');

      if(that.$route.params.id) {
        customerID = that.$route.params.id;
      } else {
        customerID = that.myUser.customer;
      }
  
      that.myCustomer = await that.$http.get(`/customer/${customerID}`).then(response => response.data);

      that.customerForm = _.cloneDeep(that.myCustomer);
    },
    methods: {
      updateCustomer: async function () {
        let that = this;
  
        const response = await that.$http.patch(`/customer/${that.myCustomer._id}`, that.customerForm);
  
        // Show success message.
        that.$notify({
            message: `Company Profile Updated!`,
            timeout: 5000,
            type: "success",
          });
      },
      updateLatLng: function (latlng) {
        this.customerForm.latitude = latlng.lat;
        this.customerForm.longitude = latlng.lng;
      },
      updateZoom: function (zoom) {
        this.customerForm.zoom = Math.ceil(zoom);
      },
    },
  };
  </script>
  
  <style>
  </style>