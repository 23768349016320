<template>
    <div>
        <component
            v-for="item in sortedItemList"
            :is="item.base"
            :key="item._id"
            :input="item"
            :exercise="exercise"
            v-on:removefromexercise="$emit('removefromexercise', $event)"
            v-on:editinexercise="$emit('editinexercise', $event)"
        />

        <!-- <b-table
            :items="itemList"
            :fields="[
                { key: 'sendTime', label: 'Send Time' },
                { key: 'title', label: 'Title' },
                { key: 'base', label: 'Base' },
                { key: 'description', label: 'Description' }
            ]"
            striped
            hover
        >
            <template #cell(title)="row">
                <span>{{ row.item.title ? row.item.title : row.item.name }}</span>
            </template>

            <template #cell(description)="row">
                <div style="width: 100px; overflow-wrap: break-word;" v-html="row.item.description"></div>
            </template>

            <template #cell(sendTime)="row">
                <ShowSendTimeBadge
                    :exercise="exercise"
                    :item="row.item"
                />
            </template>
        </b-table> -->
    </div>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
    name: "ExerciseInjectAllInjectList",
    mixins: [SocketIOMixin],
    components: {},
    props: {
        exercise: {
            type: Object,
            required: true,
        },
    },
    data: function () {
        return {
            socketEventName: ""
        };
    },
    computed: {},
    created: function () {},
    mounted: function () {
        const that = this;

        that.find();

        // that.socketIOSubscribe({
        //     name: `${that.exercise._id}-inject-list`
        // });
    },
    methods: {
        find: async function () {
            const that = this;
            // that.itemList =  await that.$http.get(`/inject?exercise=${that.exercise._id}`).then((response) => response.data);
            // console.log(that.widgets);

            for (const key in that.widgets) {
                
                const item = that.widgets[key];
                console.log(item);
                if (item.inject) {
                    
                    try {
                        const injects = await that.$http.get(`${item.baseUrl}?exercise=${that.exercise._id}`).then((response) => response.data);

                        // Iterate over the injects and add set item.base to the item
                        injects.forEach((inject) => {
                            inject.base = item.base;
                        });
                        that.itemList.push(...injects);
                        console.log(injects);
                    } catch (error) {
                        console.error(error);
                    }
                }
            }

        },
    },
    // beforeRouteLeave(to, from, next) {
    //     // this.sockets.unsubscribe(this.socketEventName);
    //     // this.sockets.unsubscribe(`${this.exercise._id}-inject-remove`);
    // },
};
</script>
