<template>
  <div
    class="tw-border tw-rounded"
  >
    <b-container fluid>
      <b-row>
        <b-col class="tw-pt-4">
          <b-form-input
            id="input-large"
            size="lg"
            v-model="keywords"
            @keyup="find()"
            placeholder="Search Description"
          ></b-form-input>
        </b-col>
        <b-col cols="auto" v-if="!add" class="tw-pt-4">
          <b-button
            :to="{ name: 'media-library-upload' }"
            size="lg"
          >
            Upload Media
          </b-button>
        </b-col>
      </b-row>
    </b-container>

    <hr />
    <b-container fluid>
      <b-row>
        <b-col cols="auto" v-for="item in itemList" :key="item._id">
          <b-card v-if="item.status == 'active'">
            <b-img-lazy
              v-bind="mainProps"
              :src="getImageUrl(item)"
              alt="Image 1"
            ></b-img-lazy>

            <b-popover :target="`pop-${item._id}`" triggers="hover" placement="top">
              <template #title>{{ clipText(item.originalName ? item.originalName : "Document") }}</template>
              
              {{ clipText(item.description, 100) }}
            </b-popover>

              <b-button
                :id="`pop-${item._id}`"
                @click="$emit('addtobroadcastform', item)"
                variant="outline-secondary"
                block
                v-if="add"
              >
                <i class="fas fa-plus"></i>
                {{ clipText(item.originalName ? item.originalName : "Document") }}
              </b-button>
              <b-button
                :id="`pop-${item._id}`"
                :to="{ name: 'media-library-detail', params: { id: item._id } }"
                variant="outline-primary"
                block
                v-else
              >
              {{ clipText(item.originalName ? item.originalName : "Document", 20) }}
              </b-button>

            </b-card>
        </b-col>
      </b-row>
    </b-container>
    <br />
  </div>
</template>
<script>
import { HTTP } from "@/util/http-common.js";
// import HandleErrors from "@/util/handle-errors";

import SocketIOMixin from '@/util/socketio';
export default {
  mixins: [SocketIOMixin],
  components: {},
  props: {
    add: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mainProps: {
        center: true,
        fluid: true,
        blank: true,
        rounded: true,
        thumbnail: true,
        blankColor: "#bbb",
        // width: 250,
        // height: 250,
        class: "my-5",
      },
      keywords: "",
    };
  },
  created() {
    this.find();
  },
  mounted() {},
  methods: {
    async find() {
      const that = this;
      let data = {};

      if (that.keywords.trim().length) {
        data.params = { keywords: that.keywords };
      }

      that.itemList = await that.$http.get("/media-library", data).then(
        (response) => response.data
      );

      that.socketIOSubscribe({
        name: `${that.customer._id}-media-library`,
        callback: (data) => {
          const index = _.findIndex(that.itemList, (o) => {
            return o._id == data._id;
          });

          if (index >= 0) {
            that.itemList.splice(index, 1);
          }

          that.itemList.push(data);
        },
      });
    },
    getImageUrl(item) {
      const { width, height } = this.mainProps;
      let extention = item.name.split(".")[1];

      if (item.thumbnail) {
        return `${process.env.VUE_APP_AXIOS_BASE_URL}/uploads/${
          item.thumbnail
        }`;
      } else if (!item.name) {
        return `https://via.placeholder.com/250x250.png?text=Document`;
      } else {
        return `https://via.placeholder.com/250x250.png?text=${extention}`;
      }
    },
    clipText(text, length) {
      if (text.length > length) {
        return text.substring(0, length) + "...";
      } else {
        return text;
      }
    },
  },
};
</script>
