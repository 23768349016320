<template>
  <div class="w-100 h-100">
    <div class="header pb-6 d-flex align-items-center profile-header">
      <!-- Mask -->
      <span class="mask bg-gradient-default opacity-8"></span>
      <!-- Header container -->
      <b-container fluid class="d-flex align-items-center">
        <b-row >
          <b-col lg="12" md="12">
            <h1 class="display-2 text-white">Hello, {{  myUser.name || myUser.email }}</h1>
            <!-- <p class="text-white mt-0 mb-5">This is your profile page. You can see the progress you've made with your
              work and manage your projects or assigned tasks</p>
            <a href="#!" class="btn btn-neutral">Edit profile</a> -->
          </b-col>
        </b-row>
      </b-container>
    </div>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="6">
          <b-card no-body class="card-profile" img-src="img/theme/img-1-1000x600.jpg" alt="Image placeholder" img-top>
            <b-row class="justify-content-center">
              <b-col lg="3" class="order-lg-2">
                <div class="card-profile-image">
                  <a href="#">
                    <b-img src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light" rounded="circle" />
                  </a>
                </div>
              </b-col>
            </b-row>

            <!-- <b-card-header class="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
              <div class="d-flex justify-content-between">
                <a href="#" class="btn btn-sm btn-info mr-4">Connect</a>
                <a href="#" class="btn btn-sm btn-default float-right">Message</a>
              </div>
            </b-card-header> -->

            <b-card-body class="pt-7">
              <!-- <b-row>
                <b-col>
                  <div class="card-profile-stats d-flex justify-content-center">
                    <div>
                      <span class="heading">22</span>
                      <span class="description">Friends</span>
                    </div>
                    <div>
                      <span class="heading">10</span>
                      <span class="description">Photos</span>
                    </div>
                    <div>
                      <span class="heading">89</span>
                      <span class="description">Comments</span>
                    </div>
                  </div>
                </b-col>
              </b-row> -->
              <div class="text-center">
                <h5 class="h3">
                  {{ myUser.name || myUser.email }}, <span class="font-weight-light" v-if="myUser._id == user._id">(me), {{ myCustomer.name }}</span>
                  <span class="font-weight-light" v-else>{{ myCustomer.name }}</span>
                </h5>
                <div class="h5 font-weight-300">
                  <i class="ni location_pin mr-2"></i> {{ myUser.email }}
                </div>
                <div class="h5 mt-4" v-if="myUser.systemRoles && myUser.systemRoles.length">
                  <i class="ni business_briefcase-24 mr-2"></i>System Roles
                </div>
                <div v-if="myUser.systemRoles && myUser.systemRoles.length">
                  <i class="ni education_hat mr-2"></i> {{ roles }}
                </div>
              </div>
            </b-card-body>
          </b-card>

        </b-col>
        <b-col xl="6">
          <card>

              <!-- <h2>Update Profile</h2> -->
              <h6 class="heading-small text-muted mb-4">Update Profile</h6>
              <b-container fluid>
                <b-row class="my-3">
                  <b-col>
                    <b-form-input
                      size="lg"
                      v-model="userForm.role"
                      placeholder="Company Role"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="my-3">
                  <b-col>
                    <b-form-input
                      size="lg"
                      v-model="userForm.name"
                      placeholder="Name"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="my-3">
                  <b-col>
                    <b-form-input
                      size="lg"
                      v-model="userForm.email"
                      placeholder="Email"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row v-if="$store.state.user.user.isSuperUser || ($store.state.user.user.systemRoles.indexOf('admin') >= 0 && !isMe())">
                  <b-col>
                    <b-form-checkbox
                      id="active"
                      v-model="userForm.active"
                      name="active"
                      :value="true"
                      :unchecked-value="false"
                    >
                      Active
                    </b-form-checkbox>
                  </b-col>
                </b-row>

                <b-row class="my-3" v-if="isAdminOrSuperUser()">
                  <b-col>
                    <multiselect
                      size="lg"
                      v-model="userForm.systemRoles"
                      :multiple="true"
                      :options="['admin', 'exercise-developer', 'exercise-controller']"
                    ></multiselect>
                  </b-col>
                </b-row>

                <b-row class="my-3">
                  <b-col>
                    <b-button
                      variant="primary"
                      block
                      size="lg"
                      @click="updateUser()"
                    >
                      Save
                    </b-button>
                  </b-col>
                </b-row>
              </b-container>

              <br><hr><br>

              <h2>Change Password</h2>
              <b-container fluid>
                <b-row class="my-3" v-if="isMe()">
                  <b-col>
                    <b-form-input
                      type="password"
                      size="lg"
                      v-model="passwordForm.oldPassword"
                      placeholder="Password"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="my-3">
                  <b-col>
                    <b-form-input
                      type="password"
                      size="lg"
                      v-model="passwordForm.newPassword"
                      placeholder="New Password"
                    >
                    </b-form-input>
                  </b-col>
                </b-row>

                <b-row class="my-3">
                  <b-col>
                    <b-form-input
                      type="password"
                      size="lg"
                      v-model="passwordForm.confirmNewPassword"
                      placeholder="Confirm Password"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="my-3">
                  <b-col>
                    <b-button
                      variant="primary"
                      block
                      size="lg"
                      :disabled="
                        !passwordForm.oldPassword &&
                        !passwordForm.newPassword &&
                        !passwordForm.confirmNewPassword
                      "
                      @click="changePassword()"
                    >
                      Change
                    </b-button>
                  </b-col>
                </b-row>
              </b-container>
          </card>
        </b-col>
      </b-row>
      <!-- <b-row>
        <b-col>
          <b-card
            img-src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
            img-alt
            img-top
            tag="article"
            class="mb-2"
          >
            <b-card-title>
              {{ myUser.name || myUser.email }}
              <small v-if="myUser._id == user._id">(me)</small>
            </b-card-title>
            <b-list-group flush>
              <b-list-group-item d-flex justify-content-between>
                <strong>Email:</strong>
                {{ myUser.email }}
              </b-list-group-item>

              <b-list-group-item d-flex justify-content-between>
                <strong>Organization:</strong>
                {{ myCustomer.name }}
              </b-list-group-item>

              <b-list-group-item d-flex justify-content-between v-if="myUser.systemRoles">
                <strong>Active: </strong>
                <h4 class="tw-inline-block">
                  <b-badge variant="success" v-if="myUser.active">Yes</b-badge>
                  <b-badge variant="danger" v-else>No</b-badge>
                </h4>
              </b-list-group-item>

              <b-list-group-item d-flex justify-content-between v-if="myUser.systemRoles">
                <strong>Roles:</strong>
                {{ roles }}
              </b-list-group-item>

            </b-list-group>
          </b-card>
        </b-col>

        <b-col>
        </b-col>
      </b-row> -->
    </b-container>
  </div>
</template>

<script>

import { HTTP } from "@/util/http-common.js";
// import HandleErrors from "@/utilities/handle-errors";

// import BaseDialogMixin from "@/components/mixins/BaseDialogMixin.vue";
// import BaseAlertMixin from "@/components/mixins/BaseAlertMixin.vue";

import { mapActions, mapState } from "vuex";

import * as _ from "lodash";

export default {
  // mixins: [BaseDialogMixin, BaseAlertMixin],
  data: function () {
    return {
      userForm: {},
      passwordForm: {},
      roles: '',
      myUser: {},
      myCustomer: {}
    };
  },
  computed: {
    ...mapState("user", ["user", "myGroups"]),
  },
  created: async function () {
    const that = this;

    // Get the user being requested, set the myUser and userForm.
    const response = await that.$http.get(`/user/${that.$route.params.id}`);
    that.myUser = response.data;
    that.userForm = _.cloneDeep(that.myUser);

    // Set the roles.
    const roles = [];
    _.each(that.myUser.systemRoles, (role) => {
      roles.push(role.split('-').join(' '))
    });
    that.roles = roles.join(', ');

    // Get the customer for this user.
    that.myCustomer = await that.$http.get(`/customer/${that.myUser.customer}`).then(response => response.data);
  },
  methods: {
    ...mapActions("user", ["setUser", "getUser", "getMyGroups", "setMyGroups"]),
    async changePassword() {
      const that = this;

      try {
        const response = await that.$http.patch(`/change-password/${that.myUser._id}`, that.passwordForm);
      } catch (error) {
        return that.$notify({
          message: error.response.data.message,
          timeout: 5000,
          type: "error",
        });
      }
      that.passwordForm = {};

      // Notify.
      that.$notify({
        message: "Password changed.",
        timeout: 5000,
        type: "success",
      });
    },
    async updateUser() {
      const that = this;

      try {
        const response = await that.$http.patch(`/user/${that.myUser._id}`, that.userForm);
        that.myUser = response.data;

        if(that.isMe()) {
          that.$store.commit('user/setUser', response.data);
          window.localStorage.setItem('user', JSON.stringify(response.data));
        }
      } catch (error) {
        return that.$notify({
          message: error.response.data.message,
          duration: 10000,
          type: "danger"
        });
      }

      const roles = [];
      _.each(that.myUser.systemRoles, (role) => {
        roles.push(role.split('-').join(' '))
      });
      that.roles = roles.join(', ');

      that.$notify({
        message: "Account information saved!",
        duration: 10000,
        type: "success"
      });
    },
    isAdminOrSuperUser() {
      return this.$store.state.user.user.isSuperUser || this.$store.state.user.user.systemRoles.indexOf('admin') >= 0;
    },
    isMe() {
      return this.myUser._id == this.user._id;
    }
  },
};
</script>

<style>
</style>