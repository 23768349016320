<template>
  <b-container fluid>

    <b-modal v-model="modalAddUser" id="modalAddUser" title="Add User" hide-footer centered scrollable>
      <b-container fluid>
        <b-row class="my-3">
          <b-col sm="12">
            <b-form-input
              size="lg"
              v-model="createUserForm.name"
              placeholder="Name"
              autocomplete="off"
            ></b-form-input>
          </b-col>
        </b-row>

        <b-row class="my-3">
          <b-col sm="12">
            <b-form-checkbox
              id="active"
              v-model="createUserForm.active"
              name="active"
              :value="true"
              :unchecked-value="false"
            >Active</b-form-checkbox>
          </b-col>
        </b-row>

        <b-row class="my-3">
          <b-col sm="12">
            <b-form-input
              type="password"
              size="lg"
              v-model="createUserForm.password"
              placeholder="Password"
              autocomplete="off"
            ></b-form-input>
          </b-col>
        </b-row>

        <b-row class="my-3">
          <b-col sm="12">
            <b-form-input
              type="password" 
              size="lg"
              v-model="createUserForm.confirmPassword"
              placeholder="Confirm Password"
              autocomplete="off"
            ></b-form-input>
          </b-col>
        </b-row>

        <b-row class="my-3">
          <b-col sm="12">
            <b-form-input
              size="lg"
              v-model="createUserForm.email"
              placeholder="Email"
              autocomplete="off"
            ></b-form-input>
          </b-col>
        </b-row>

        <b-row class="my-3">
          <b-col sm="12">
            <b-form-input
              size="lg"
              v-model="createUserForm.role"
              placeholder="Company Role"
              autocomplete="off"
            ></b-form-input>
          </b-col>
        </b-row>

        <b-row class="my-3">
          <b-col sm="12">
            <multiselect
              size="lg"
              v-model="createUserForm.systemRoles"
              :multiple="true"
              :options="['admin', 'exercise-developer', 'exercise-controller']"
            ></multiselect>
          </b-col>
        </b-row>

        <b-row class="my-3">
          <b-col sm="12">
            <b-button
              class="mt-2"
              variant="primary"
              block
              size="lg"
              @click="createUser()"
            >Sign Up</b-button>
          </b-col>
        </b-row>

        <b-row class="my-3">
          <b-col sm="12">
            <b-button
              class="mt-2"
              variant="warning"
              block
              size="lg"
              @click="cancelCreateUser()"
            >Cancel</b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>

    <b-container fluid class="my-3">
      <b-row>
        <b-col>
          <b-form-select
            v-if="customers.length"
            v-model="selectedCustomer"
            :options="customers"
            size="lg"
            value-field="_id"
            text-field="name"
            disabled-field="notEnabled"
          >
            <template #first>
              <b-form-select-option :value="null">-- Select A Customer To Filter --</b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col cols="auto" v-if="$store.state.user.user.isSuperUser || $store.state.user.user.systemRoles.indexOf('admin') >= 0">
          <b-button size="lg" variant="primary" @click="$router.push({ name: 'customer-details-id', params: { id: selectedCustomer } });">
            Edit Account Profile
          </b-button>
        </b-col>
        <b-col cols="auto" v-if="$store.state.user.user.isSuperUser || $store.state.user.user.systemRoles.indexOf('admin') >= 0">
          <b-button size="lg" @click="modalAddUser = true">
            Add User
          </b-button>
        </b-col>
      </b-row>

    </b-container>

    <b-container fluid>
      <b-row>
        <b-col>
          <card>
            <h2>Users</h2>
            <b-table striped hover :fields="fields" :items="users">
              <template #cell(name)="data">
                <template v-if="$store.state.user.user.isSuperUser || $store.state.user.user.systemRoles.indexOf('admin') >= 0">
                  <b-link :to="{name: 'user-details', params: {id: data.item._id}}">
                    {{ data.value }}
                  </b-link>
                </template>
                <template v-else>
                  {{ data.value }}
                </template>
              </template>

              <template #cell(active)="data">
                <h4>
                  <b-badge variant="success" v-if="data.value">Yes</b-badge>
                  <b-badge variant="danger" v-else>No</b-badge>
                </h4>
              </template>

              <template #cell(systemRoles)="data">
                {{ data.value.join(", ")}}
              </template>
            </b-table>
          </card>
        </b-col>
      </b-row>
    </b-container>

  </b-container>
</template>

<script>
// import { HTTP } from "@/util/http-common";
import { HTTP } from "@/util/http-common.js";
// // import HandleErrors from "@/utilities/handle-errors";

// import DashboardBaseTemplate from "@/layouts/DashboardBaseTemplate.vue";

export default {
  // mixins: [DashboardBaseTemplate],
  data: function () {
    return {
      fields: ['name', 'email', 'role', 'systemRoles', 'active'],
      users: [],
      customers: [],
      myCustomer: {},
      selectedCustomer: null,
      modalAddUser: false,
      createUserForm: {}
    };
  },
  computed: {},
  watch: {
    selectedCustomer: function(val) {
      if (val) {
        this.findUsers();
        this.$router.replace({
          name: 'users',
          query: {
            customer: val
          }
        });
      }
    }
  },
  created: async function () {
    const that = this;

    if(that.$route.query.customer) {
      that.selectedCustomer = that.$route.query.customer;
    }

    if(that.$store.state.user.user.isSuperUser) {
      that.findCustomers();
    }

    that.findUsers();

    if(!that.selectedCustomer) {
      that.selectedCustomer = that.$store.state.user.customer._id;
    }
  },
  methods: {
    async findUsers() {
      const that = this;
      that.users = await that.$http.get("/user", {
        params: {
          customer: that.selectedCustomer
        }
      }).then(
        (response) => response.data
      );
    },
    async findCustomers() {
      const that = this;
      that.customers = await that.$http.get("/customer").then(
        (response) => response.data
      );
    },
    async createUser() {
      const that = this;

      that.createUserForm.customer = that.selectedCustomer;

      if(that.createUserForm.password !== that.createUserForm.confirmPassword) {
        return that.$notify({
            message: `Passwords do not match.`,
            timeout: 5000,
            type: "error",
          });
      }

      try {
        const response = await that.$http.post(`/user`, that.createUserForm);
        that.createUserForm = {};
        that.modalAddUser = false;
        that.findUsers();
      } catch (error) {
        that.$notify({
            message: error.response.data.message,
            timeout: 5000,
            type: "error",
          });
      }

      that.$notify({
            message: `User added successfully.`,
            timeout: 10000,
            type: "success",
          });
    },
    cancelCreateUser() {
      this.createUserForm = {};
      this.modalAddUser = false;
    }
  },
};
</script>

<style>
</style>